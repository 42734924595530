<template>
  <div class="page-accounting">
    <tool-navigation/>
    <div class="container gpt-settings mb-5">
      <div class="openai-settings">
        <div class="pb-3 heading row-flex between">
          <strong>Create New Task</strong>
          <b-icon style="cursor:pointer;" variant="primary" icon="gear" @click="showSettings"></b-icon>
        </div>
        <b-row class="pb-2" v-show="settingsShow">
          <b-col>
            <div class="label-wrapper">
              <label for="temperature">Temperature</label>
              <a href="https://platform.openai.com/docs/api-reference/completions/create#completions-create-temperature" target="_blank"><b-icon variant="primary" icon="info"></b-icon></a>
            </div>
            <b-form-input
              id="temperature"
              class="border-bottom rounded-0 bg-light mt-1 p-2"
              v-model.number="task.temperature"
              type="number"
              min="0"
              max="2"
              step="0.1"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="pb-2">
          <b-col>
            <div class="label-wrapper between">
              <label for="data">Data <span style="color: red">*</span></label>
            </div>
            <b-textarea
              id="data"
              class="border-0 border-bottom rounded-0 mb-3 bg-light mt-1 w-100 p-2"
              rows="10"
              v-model="task.data"
            ></b-textarea>
          </b-col>
        </b-row>
        <b-row class="pb-2">
          <b-col>
            <div class="label-wrapper">
              <label for="prompt">Prompt <span style="color: red">*</span></label>
            </div>
            <b-textarea
              id="prompt"
              class="border-0 border-bottom rounded-0 mb-3 bg-light mt-1 w-100 p-2"
              rows="10"
              v-model="task.prompt"
            ></b-textarea>
          </b-col>
        </b-row>
        <b-row class="pb-2" align-h="between">
          <b-col cols="10">
            <div class="label-wrapper">
              <label for="task_name">Task Name (For Search) <span style="color: red">*</span></label>
            </div>
            <b-form-input
              id="task_name"
              class="border-bottom rounded-0 bg-light mt-1 p-2"
              v-model="task.name"
              required
              placeholder=""
            ></b-form-input>
          </b-col>
          <b-col class="col-flex">
            <div class="label-wrapper"></div>
            <b-button class="mt-1" variant="outline-primary" @click="addTask">Add Task</b-button>
          </b-col>
        </b-row>
      </div>
      <div class="tasks mt-4">
        <div class="pb-3 heading"><strong>Tasks</strong></div>
        <b-row v-if="tasks.length > 0" class="pb-2 items">
          <b-col>
            <div v-for="task of tasks" :key="task._id" class="mb-3">
              <b-card>
                <b-row style="align-items: center" align-h="between">
                  <b-col cols="7">
                    <b-link :class="['text-primary', task.status === 'Complete' || task.status === 'Error' ? 'task-enabled' : 'task-disabled']" @click="showTaskAnswer(task._id)">{{ task.name }} ({{ task.tech_data.created_at }}) {{ task.additional_info }}</b-link>
                  </b-col>
                  <b-col class="row-flex">
                    <p><strong>Status:</strong> {{ task.status }}</p>
                  </b-col>
                  <b-col cols="1" class="row-flex">
                    <b-icon :class="['task-refresh', task.status === 'Complete' || task.status === 'Error' ? 'task-enabled' : 'task-disabled']" variant="primary" icon="arrow-clockwise" @click="refreshTask(task._id)"></b-icon>
                    <b-icon :class="['task-delete', 'ml-2']" variant="primary" icon="trash" @click="deleteTask(task._id)"></b-icon>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="mt-2 ml-4" v-show="task.show">
                <div class="mb-3">
                  <b-link :class="['text-primary']" @click="showTaskTechData(task._id)" v-html="!task.showTechData ? 'Show Tech Data' : 'Hide Tech Data'"></b-link>
                </div>
                <div v-show="task.showTechData">
                  <strong>Data:</strong>
                  <b-textarea
                    name="data"
                    class="border-0 border-bottom rounded-0 mb-3 bg-light mt-1 w-100 p-2"
                    rows="6"
                    v-model="task.tech_data.data"
                    readonly
                  ></b-textarea>
                </div>
                <div v-show="task.showTechData">
                  <strong>Prompt:</strong>
                  <b-textarea
                    name="prompt"
                    class="border-0 border-bottom rounded-0 mb-3 bg-light mt-1 w-100 p-2"
                    rows="6"
                    v-model="task.tech_data.prompt"
                    readonly
                  ></b-textarea>
                </div>
                <b-row class="pb-2 pl-3 col-flex" v-if="task.engine && task.showTechData">
                  <div><strong>Engine:</strong> {{ task.engine }}</div>
                </b-row>
                <b-row class="pb-2 pl-3 col-flex" v-show="task.showTechData" v-if="task.engine !== 'google-generative-ai'">
                  <div><strong>N:</strong> {{ task.tech_data.n }}</div>
                  <div><strong>Presence Penalty:</strong> {{ task.tech_data.presence_penalty }}</div>
                  <div><strong>Max Tokens:</strong> {{ task.tech_data.max_tokens }}</div>
                  <div><strong>Frequency Penalty:</strong> {{ task.tech_data.frequency_penalty }}</div>
                  <div><strong>Temperature:</strong> {{ task.tech_data.temperature }}</div>
                  <div><strong>Top P:</strong> {{ task.tech_data.top_p }}</div>
                  <div><strong>Model:</strong> {{ task.tech_data.model }}</div>
                  <div><strong>Created at:</strong> {{ task.tech_data.created_ad }}</div>
                  <div><strong>Ended at:</strong> {{ task.tech_data.ended_at }}</div>
                </b-row>
                <b-textarea
                  class="border-0 border-bottom rounded-0 mb-3 bg-light mt-1 w-100 p-2"
                  rows="10"
                  readonly
                  v-model="task.answer"
                ></b-textarea>
                <b-button class="mt-1" variant="outline-primary" @click="exportTaskToCSV(task._id)">Export to CSV</b-button>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>Tasks Not Found</b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { showSnackbar } from '@/services'
import mtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'
import ToolNavigation from '@/components/ToolNavigation.vue';

export default {
  components: { ToolNavigation },
  data() {
    return {
      search: '',
      settingsShow: false,
      task: {
        engine: 'llama',
        temperature: 1,
        prompt: '',
        data: '',
        name: '',
        show: false,
        showTechData: false,
        status: 'New',
        answer: '',
        additional_info: ''
      },
      tasks: []
    }
  },
  methods: {
    showTaskAnswer(_id) {
      const index = this.tasks.findIndex((t) => t._id === _id)
      this.tasks[index].show = !this.tasks[index].show
    },
    showTaskTechData(_id) {
      const index = this.tasks.findIndex((t) => t._id === _id)
      this.tasks[index].showTechData = !this.tasks[index].showTechData
    },
    showSettings() {
      this.settingsShow = !this.settingsShow
    },
    async deleteTask(_id) {
      await mtApi.deleteGptTask(_id)
      await this.getTasks()
      showSnackbar({
        mode: 'success',
        text: `Task deleted!`
      })
    },
    async refreshTask(_id) {
      await mtApi.refreshGptTask(_id,{
        status: 'Refresh'
      })
      await this.getTasks()
      showSnackbar({
        mode: 'success',
        text: `Task send to refresh!`
      })
    },
    async addTask() {
      try {
        if (!this.task.name) {
          throw new Error('Task name required!')
        }
        if (!this.task.prompt) {
          throw new Error('Task prompt required!')
        }
        if (!this.task.data) {
          throw new Error('Task Data required!')
        }
        if (this.task.engine === 'openai-gpt') {
          this.task.additional_info = `Creator: ${this.me.full_name};`
        } else {
          this.task.additional_info = `Creator: ${this.me.full_name};`
        }
        await mtApi.addGptTask(this.task)
        await this.getTasks()
        this.task.data = ''
        this.task.prompt = ''
        this.task.name = ''
        showSnackbar({
          mode: 'success',
          text: 'New Task added!'
        })
      } catch (err) {
        showSnackbar({
          mode: 'error',
          text: err.message.join('\n')
        })
      }
    },
    async getTasks() {
      const tasks = await mtApi.getGptTasks()
      this.tasks = tasks.map((t) => {
        const existTask = this.tasks.find((e) => e._id === t._id)
        const date_created_at = new Date(t.created_at)
        const date_ended_at = new Date(t.ended_at)
        return {
          _id: t._id,
          name: t.name,
          status: t.status,
          engine: t.engine,
          answer: t.answer ? t.answer.join('\n') : t.answer,
          show: existTask ? existTask.show : false,
          additional_info: t.additional_info,
          showTechData: existTask ? existTask.showTechData : false,
          tech_data: {
            data: t.data,
            prompt: t.prompt,
            n: t.n,
            presence_penalty: t.presence_penalty,
            max_tokens: t.max_tokens,
            frequency_penalty: t.frequency_penalty,
            temperature: t.temperature,
            top_p: t.top_p,
            model: t.model,
            answer: t.answer,
            created_at: date_created_at.toLocaleString('en-US'),
            ended_at: date_ended_at.toLocaleString('en-US')
          }
        }
      })
    },
    async exportTaskToCSV(_id) {
      const task = this.tasks.find((t) => t._id === _id)
      const csvData = task.tech_data.answer.map(row => {
          let formattedValue = row
          if (typeof formattedValue === 'string') {
            formattedValue = formattedValue.replace(/"/g, '""')
            if (
              formattedValue.includes(',') ||
              formattedValue.includes('"')
            ) {
              formattedValue = `"${formattedValue}"`
            }
          }
          return formattedValue
        })
        .join('\n')
      const fileData = new Blob([csvData], { type: 'text/csv' })
      const fileUrl = URL.createObjectURL(fileData)
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = `task-${_id}.csv`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  },
  computed: {
    ...mapGetters(['me'])
  },
  async mounted() {
    try {
      await this.getTasks()
      setInterval(async () => {
        await this.getTasks()
      }, 5000)
    } catch (err) {
      showSnackbar({
        mode: 'error',
        text: err.message
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gpt-settings {
  .heading {
    font-size: 25px;
  }
  .openai-settings {
    .label-wrapper {
      display: flex;
      min-height: 35px;
      align-items: center;
      &.between {
        justify-content: space-between;
      }
      a {
        font-size: 30px;
      }
      label {
        margin: 0;
      }
    }
    select {
      border-bottom: 1px solid #dee2e6 !important;
    }
  }
  .between {
    justify-content: space-between !important;
  }
  .col-flex {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  .row-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .task-refresh {
    font-size: 20px;
    cursor: pointer;
  }
  .task-delete {
    cursor: pointer;
  }
  .task-disabled {
    pointer-events: none;
    color: grey !important;
  }
  p {
    margin: 0;
  }
}
</style>
