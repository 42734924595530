<template>
  <hero-navigation>
      <div class="projects-nav-elements">
        <router-link class="button-outline-blue" to="/tools/gpt">
          GPT
        </router-link>
        <router-link class="button-outline-blue" to="/tools/content-table">
          Content Table
        </router-link>
      </div>
    </hero-navigation>
</template>

<script>
import HeroNavigation from '@/components/HeroNavigation.vue'
export default {
  components: { HeroNavigation },
}
</script>

<style lang="scss" scoped>

</style>